import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import AdminRoute from './AdminRoute';
import DarkModeRouteWrapper from './DarkModeRouteWrapper';
import PrivateRoute from './PrivateRoute';
import LazyLoadingFallback from './SharedComponents/LazyLoadingFallback';
import ForgotPass from './StartingScreens/PreLogin/forgotPassword';
import LandingPage from './StartingScreens/PreLogin/landingPage';
import LoginPage from './StartingScreens/PreLogin/loginPage';
import PreLoginLayout from './StartingScreens/PreLogin/PreLoginLayout';
import SignUpPage from './StartingScreens/PreLogin/signupScreen';
import AlertProvider from '../contexts/AlertContext';
import { AuthProvider } from '../contexts/AuthContext';

// Lazy-loaded components
const AdminPortal = lazy(() => import('./AdminPortal'));
const EventDisplay = lazy(
  () => import('./MainComponents/EventDisplay/eventDisplay'),
);
const FollowersFeed = lazy(
  () => import('./MainComponents/FollowingFeed/FollowingFeed'),
);
const ProfileScreen = lazy(() => import('./MainComponents/ProfileScreen'));
const Dashboard = lazy(() => import('./StartingScreens/PostLogin/dashboard'));
const DashboardProvider = lazy(() =>
  import('./StartingScreens/PostLogin/dashboardProvider').then((module) => ({
    default: module.DashboardProvider,
  })),
);
const UpdateProfile = lazy(
  () => import('./StartingScreens/PostLogin/updateProfile'),
);
const WelcomeScreen = lazy(
  () => import('./StartingScreens/PostLogin/welcomeScreen'),
);

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AlertProvider>
          <Suspense fallback={<LazyLoadingFallback />}>
            <Routes>
              {/* Pre-login routes - no dark mode */}
              <Route element={<PreLoginLayout />}>
                <Route path='/' element={<LandingPage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/signup' element={<SignUpPage />} />
                <Route path='/forgot-password' element={<ForgotPass />} />
              </Route>

              {/* Post-login routes - with dark mode */}
              <Route
                path='/welcome'
                element={
                  <DarkModeRouteWrapper>
                    <Suspense fallback={<LazyLoadingFallback />}>
                      <WelcomeScreen />
                    </Suspense>
                  </DarkModeRouteWrapper>
                }
              />
              <Route
                path='/dashboard'
                element={
                  <DarkModeRouteWrapper>
                    <PrivateRoute>
                      <Suspense fallback={<LazyLoadingFallback />}>
                        <DashboardProvider>
                          <Dashboard />
                        </DashboardProvider>
                      </Suspense>
                    </PrivateRoute>
                  </DarkModeRouteWrapper>
                }
              />
              <Route
                path='/update-profile'
                element={
                  <DarkModeRouteWrapper>
                    <PrivateRoute>
                      <Suspense fallback={<LazyLoadingFallback />}>
                        <UpdateProfile />
                      </Suspense>
                    </PrivateRoute>
                  </DarkModeRouteWrapper>
                }
              />
              <Route
                path='/profile'
                element={
                  <DarkModeRouteWrapper>
                    <PrivateRoute>
                      <Suspense fallback={<LazyLoadingFallback />}>
                        <ProfileScreen />
                      </Suspense>
                    </PrivateRoute>
                  </DarkModeRouteWrapper>
                }
              />
              <Route
                path='/profile/:userId'
                element={
                  <DarkModeRouteWrapper>
                    <PrivateRoute>
                      <Suspense fallback={<LazyLoadingFallback />}>
                        <ProfileScreen />
                      </Suspense>
                    </PrivateRoute>
                  </DarkModeRouteWrapper>
                }
              />
              <Route
                path='/followers'
                element={
                  <DarkModeRouteWrapper>
                    <PrivateRoute>
                      <Suspense fallback={<LazyLoadingFallback />}>
                        <FollowersFeed />
                      </Suspense>
                    </PrivateRoute>
                  </DarkModeRouteWrapper>
                }
              />
              {/* Support for both new SEO-friendly URLs and legacy article IDs */}
              <Route
                path='/event/:articleId'
                element={
                  <DarkModeRouteWrapper>
                    <Suspense fallback={<LazyLoadingFallback />}>
                      <EventDisplay />
                    </Suspense>
                  </DarkModeRouteWrapper>
                }
              />
              <Route
                path='/event/article/:urlSlug'
                element={
                  <DarkModeRouteWrapper>
                    <Suspense fallback={<LazyLoadingFallback />}>
                      <EventDisplay />
                    </Suspense>
                  </DarkModeRouteWrapper>
                }
              />
              <Route
                path='/admin'
                element={
                  <DarkModeRouteWrapper>
                    <AdminRoute>
                      <Suspense fallback={<LazyLoadingFallback />}>
                        <AdminPortal />
                      </Suspense>
                    </AdminRoute>
                  </DarkModeRouteWrapper>
                }
              />
            </Routes>
          </Suspense>
        </AlertProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
