import { Analytics, getAnalytics, isSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

/* eslint-disable no-undef */

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const auth = getAuth(app);
export const db = getDatabase(app);
export const fsdb = getFirestore(app);
export const storage = getStorage(app);
// Initialize Firebase Functions with region
export const functions = getFunctions(app, 'us-central1');

// Connect to emulator when running locally
if (window.location.hostname === 'localhost') {
  // Connect function emulator
  connectFunctionsEmulator(functions, 'localhost', 8081);

  // Connect database emulator
  connectDatabaseEmulator(db, 'localhost', 9000);
}

let analytics: Analytics | undefined;
isSupported()
  .then((supported) => {
    if (supported) {
      analytics = getAnalytics(app);
    }
    return supported;
  })
  .catch(() => {
    // Analytics not supported - silently fail
  });

export { analytics };
export default app;
