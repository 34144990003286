import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider

import App from './components/App';

const container = document.getElementById('root');
if (!container) {
  throw new Error('Root element not found');
}
const root = ReactDOM.createRoot(container);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      {' '}
      <App />
    </HelmetProvider>
  </React.StrictMode>,
);
