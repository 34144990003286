import React from 'react';

import { BrutalistLoading } from './BrutalistUI';
import { useAuth } from '../../contexts/AuthContext';
import '../../styles/brutalist.scss';

const LazyLoadingFallback: React.FC = () => {
  const { darkMode } = useAuth();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000,
        backgroundColor: darkMode
          ? 'var(--brutalist-dark-background)'
          : 'var(--brutalist-text-light)',
      }}
    >
      <BrutalistLoading />
    </div>
  );
};

export default LazyLoadingFallback;
