import { Canvas } from '@react-three/fiber';
import React, { Suspense, lazy } from 'react';

import './BrutalistUI.scss';

// Lazy load the Earth component
const Earth = lazy(() => import('../Earth/earthLogin'));

interface IBrutalistEarthProps {
  className?: string;
}

const BrutalistEarth: React.FC<IBrutalistEarthProps> = ({ className = '' }) => {
  return (
    <div className={`brutalist-earth-container ${className}`}>
      <Canvas
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <Suspense fallback={null}>
          <ambientLight />
          <Earth />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default BrutalistEarth;
